import * as React from "react"
import Layout from "../components/structure/Layout"
import Seo from "@components/utility/Seo.jsx"
import { Link } from "gatsby"

const XyremForChildrenAdolescents = () => {

  return (
    <Layout pageClass="thank-you">
      <Seo
        canonical="/thank-you-for-signing-up"
        title="XYREM® Patient Support and Savings | XYREM® for Patients"
        description="Learn about the XYREM® (sodium oxybate) support and savings programs available to adult and pediatric patients through JazzCares®, XYREM® HQ, and the Patient and Caregiver Mentor Connection Program. See XYREM® prescribing information & BOXED Warning about serious side effects, CNS depression, misuse and abuse."
      />
      <div className="position-relative container-md">
        <section className="section section--first text-center">
          <h1 className="blueHeading">Thank you for signing up!</h1>
          <p className="blackSubtitle">You will receive information about&nbsp;XYREM.</p>
          <p className="blackSubtitle">Our goal is to provide you with resources that are specifically designed for you and your journey.  By sharing some additional details about yourself, we can ensure that we continue to send you relevant and helpful information.</p>
          <a href="https://cloud.updates.jazzpharma.com/gettingtoknowyou?ExternalId=c5e736a2-102d-424a-93a8-e9a87e9b5a67&TriggerCode=XMCBR" target="_blank" rel="noreferrer" className="button button--orange mb-48">TELL US MORE ABOUT YOURSELF</a>
          <Link to="/" className="blueLink">Return Home</Link>
        </section>
      </div>
    </Layout>
  )
}
export default XyremForChildrenAdolescents
